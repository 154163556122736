import axios from "axios";

const isInLocalhost = window.location.origin.includes("http://localhost");

const isOnDevEnvironment = window.location.origin.includes("netlify");

const devURL = "https://bibleplan-backend.herokuapp.com";
const prodURL = "https://bibleplan-backend-ovsvb.ondigitalocean.app";
const localhostURL = "http://localhost:3333";

export const baseURL = isInLocalhost
  ? localhostURL
  : isOnDevEnvironment
  ? devURL
  : prodURL;

const api = axios.create({ baseURL });

export default api;
